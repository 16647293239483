<template>
  <!--打印箱唛-->
  <Modal
    v-model="printCaseMarkTalg"
    class-name="printCaseMarkStyle"
    :title="$t('key1000836')"
    width="500"
    :transfer="false"
    :mask-closable="false"
    @on-visible-change="printCaseMarkTalgChange">
    <div class="printCaseMarkBox" ref="container" :style="styles">
      <span class="bar_code" style='font-family: IDAutomationC128S;font-size: 15pt;color: #000000' v-html="details.skuBarcode"></span>
      <p class="print_item" style="margin-top: 5px">{{ details.pickupOrderNumber }}</p>
      <p class="print_item">{{ $t('key1000184') + details.packageQuantity }}</p>
      <p class="print_item">{{ $t('key1000837') + details.createdTime }}</p>
    </div>
    <template #footer>
      <div style="text-align: center;">
        <Button @click="closeBtn">{{ $t('key1000204') }}</Button>
        <Button type="primary" @click="printBtn">{{ $t('key1000825') }}</Button>
      </div>
    </template>
    <div ref="tagTemp" style="display: none;"></div>
  </Modal>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';

export default {
  name: 'printCaseMarkModal',
  mixins: [Mixin],
  props: {
    service: String, // 当前的系统服务
    apiUrl: String, // 获取打印箱唛详情数据的接口
    typeTalg: String, // 当前打印箱唛的类型 (handoverSheetManage: 物流交接单管理，packingManage：箱/袋管理)
    printUpdate: { // 打印箱唛成功之后是否回调更新列表数据
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      details: {},
      printCaseMarkTalg: false,
      styles: {
        paddingTop: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '11pt',
        color: '#000',
        minWidth: '60pt'
      }
    };
  },
  methods: {
    // 派发关闭打印箱唛的弹窗
    closeBtn() {
      this.printCaseMarkTalg = false;
      this.$emit('closeBtn', false);
    },
    // 获取打印箱唛的详情的数据
    getPrintData(wmsPickupOrderId) {
      let v = this;
      v.axios.put(v.apiUrl + `${wmsPickupOrderId}`, null, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          data.createdTime = data.createdTime ? v.$uDate.getDataToLocalTime(data.createdTime, 'fulltime') : '';
          // 物流交接单管理
          if (v.typeTalg === 'handoverSheetManage') {
            data.pickupOrderNumber = data.ymsCarrierHandoverOrderNumber;
            data.skuBarcode = data.ymsCarrierHandoverOrderNumberBarcode;
          }
          // 箱/袋管理
          else if (v.typeTalg === 'packingManage') {
            data.pickupOrderNumber = data.pickupOrderNumber;
            data.skuBarcode = data.wmsPickupOrderNumberBarcode;
          }
          v.details = data;
          v.printCaseMarkTalg = true;
        }
      });
    },
    htmlText(callback) {
      let v = this;
      /*let str = '';
      let dom = v.deepCopy(v.$refs.container);
      let tagTemp = v.$refs.tagTemp;
      tagTemp.innerHTML = dom.parentNode.innerHTML;
      let container = tagTemp.childNodes[0];
      if (v.details) {

        container.childNodes[0].innerText = v.details.skuBarcode;
        container.childNodes[1].innerText = v.details.pickupOrderNumber;
        container.childNodes[2].innerText = '出库单数量：' + v.details.packageQuantity;
        container.childNodes[3].innerText = '创建时间：' + v.details.createdTime;
        callback && callback();
        str += tagTemp.innerHTML;
        return str;
      }*/
      return `<div class="printCaseMarkBox"
     style="padding-top: 5px; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 11pt; color: rgb(0, 0, 0); min-width: 60pt;">
    <font class="bar_code" style="font-family: IDAutomationC128S; font-size: 15pt; color: rgb(0, 0, 0);"> ${v.details.skuBarcode} </font>
    <p class="print_item" style="margin-top: 5px;">${v.details.pickupOrderNumber}</p>
    <p class="print_item">${alias1bddc4d174174d47a6dfb0437dace856.t('key1000184')}${v.details.packageQuantity}</p>
    <p class="print_item">${alias1bddc4d174174d47a6dfb0437dace856.t('key1000837')}${v.details.createdTime}</p></div>`;


    },
    replaceHtml(str) {
      const tag = {
        '&': '&amp;',
        '<': '&it;',
        '>': '&gt;',
      };

      function rel(t) {
        return tag[t] || tag;
      }

      return str.replace(/[&<>]/g, rel);
    },
    // 打印
    printBtn() {
      let v = this;
      v.publicPrintBtn(v.service, v.htmlText()).then(() => {
        v.printCaseMarkTalg = false;
      });
    },
    // 监听弹窗
    printCaseMarkTalgChange(value) {
      if (!value) {
        this.details = {};
        if (this.printUpdate) {
          this.$emit('printUpdateData', true);
        }
      }
    }
  }
};
</script>

<style lang="less">
/deep/ .printCaseMarkStyle {
  .printCaseMarkBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: #000;

    .print_item {
      margin-top: 2px;
    }

    .bar_code {
      padding: 1px;
    }
  }
}
</style>
