<template>
  <div class="container_box details_box_styles">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="250" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="threeItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1005895')+`${configCharacters}`+$t('key1005896')" prop="waybillNo">
              <Input
                v-model.trim="pageParams.waybillNo"
                :element-id="'deliveryOrder'"
                :disabled="waybillNoDisabled"
                clearable
                @on-keyup.13="changeEnterKey(2)"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1003183')" prop="bussinessId" :label-width="120">
              <Input v-model.trim="pageParams.bussinessId"
                :element-id="'bussinessId'"
                clearable
                @on-keyup.13="changeEnterKey(1)"></Input>
            </Form-item>
          </Col>
          <Button type="primary" style="margin-left: 50px;" @click="printCaseMark">{{ $t('key1000836') }}</Button>
          <Button type="primary" style="margin-left: 25px;" @click="endPackingBtn">{{ $t('key1001087') }}</Button>
        </Row>
      </Form>
    </div>
    <!--列表区域-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1005490') }}</h2>
        </template>
        <Table
          border
          :loading="tableLoading"
          max-height="650"
          :columns="tableColumns"
          :span-method="handleSpan"
          :data="handerTableData">
        </Table>
      </Card>
    </div>
    <!--打印箱唛-->
    <printCaseMarkModal
      ref="printTalg"
      :apiUrl="apiUrl"
      :service="service"
      typeTalg="packingManage">
    </printCaseMarkModal>
    <!--物流信息弹窗-->
    <logisticsInfoModal ref="logisticsInfo" @changeTabs="changeTabs"></logisticsInfoModal>
    <!--订单取消提示-->
    <orderCancelPromptModal ref="orderCancelPrompt" :btnStatus="true"></orderCancelPromptModal>
  </div>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import printCaseMarkModal from "@/components/common/printCaseMarkModal";
import orderCancelPromptModal from "@/components/common/orderCancelPromptModal";
import logisticsInfoModal from "@/components/common/logisticsInfoModal.vue";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: 'scanPacking',
  mixins: [Mixin],
  props: {
    pickupOrderNumber: {
      type: String,
      default: null
    },
    type: { // 装箱的标记
      type: String,
      default: 'scanning'
    }
  },
  data() {
    let v = this;
    return {
      apiUrl: api.put_wmsPickupOrder_printWmsPickupOrder,
      service: 'yms-supplier-service',
      pageParams: {
        bussinessId: '',
        waybillNo: ''
      },
      tableColumns: [
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000884'),
          key: 'packageCode',
          minWidth: 150,
          align: 'left'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003198'),
          key: 'warehouseName',
          minWidth: 150,
          align: 'center',
          render(h, params) {
            return h('span', v.getWarehouseName(params.row.ymsWarehouseId));
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002771'),
          key: 'trackingNumber',
          minWidth: 170,
          align: 'left'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000787'),
          minWidth: 230,
          align: 'left',
          render: (h, params) => {
            let values = params.row.valueList || [];
            let imgPath = '';
            let skuImgPath = params.row._productInfo.primaryImage;
            let spuImgPath = params.row._productInfo._spuInfo.primaryImage
            imgPath = skuImgPath ? skuImgPath : spuImgPath;
            return h('div', {
              class: 'flex mt5 mb5'
            }, [
              v.tableImg(h, params, null, imgPath),
              h('div', {class: 'ml10 flex flex-direction'}, [
                h('p', {class: 'mt5'}, alias1bddc4d174174d47a6dfb0437dace856.t('key1000396') + params.row.name),
                h('p', {class: 'mt5'}, alias1bddc4d174174d47a6dfb0437dace856.t('key1000206') + values.join('、'))
              ])
            ])
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005491'),
          key: 'info',
          minWidth: 220,
          align: 'left',
          render(h, params) {
            return h('div', {
              class: 'flex flex-direction mt5'
            }, [
              h('p', {class: 'mb5'}, 'SPU：' + (params.row.spu ? params.row.spu : '')),
              h('p', {class: 'mb5'}, 'SKU：' + (params.row.sku ? params.row.sku : '')),
              h('p', {class: 'mb5'}, `${setConfigCharacters}SKU：` + (params.row.ymsSku ? params.row.ymsSku : ''))
            ]);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000351'),
          key: 'quantity',
          minWidth: 100,
          align: 'left',
          render(h, params) {
            return h('p', params.row.quantity)
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000278'),
          key: 'options',
          align: 'center',
          width: 100,
          render(h, params) {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1003055'),
                    show: true,
                    clickFn: () => {
                      v.deletePackage(params.row.wmsPickupOrderDetailId);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ],
      tableData: [],
      handerTableData: [],
      wmsPickupOrderId: null,
      packageCodeList: [],
      warehouseList: [],
      waybillNoDisabled: false,
    };
  },
  created() {
    this.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      let v = this;
      v.warehouseList = [];
      (async () => {
        await v.getYmsWarehouse(api.get_wmsWarehouse_queryYmsWarehouse).then((data) => {
          if (data && data.length > 0) {
            v.warehouseList = data.filter((item) => {
              return item.status === 1
            });
          }
        });
        await v.Packing();
      })();
    },
    // 结束装箱的操作按钮
    endPackingBtn() {
      let v = this;
      if (v.pageParams.bussinessId !== '') {
        if (v.wmsPickupOrderId !== null) {
          if (v.tableData.length > 0) {
            v.$refs['logisticsInfo'].initLogisticsInfo(v.wmsPickupOrderId, 'endPacking');
          } else {
            v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005897'));
            return false;
          }
        } else {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005898'));
          return false;
        }
      } else {
        v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005899'));
        return false;
      }
    },
    // 回车键扫描
    changeEnterKey(value) {
      let v = this;
      // 提单编号
      if (value === 1) {
        if (v.type === 'scanning' || v.type === 'adding') {
          if (v.pageParams.bussinessId !== '') {
            v.axios.get(api.get_wmsPickupOrder_scan + `${v.pageParams.bussinessId}`).then(response => {
              if (response.data.code === 0) {
                let data = response.data.datas;
                v.wmsPickupOrderId = data.wmsPickupOrderId;
                if (data.wmsPickupOrderDetails) {
                  v.tableData = data.wmsPickupOrderDetails;
                  setTimeout(() => {
                    v.getFocus('deliveryOrder');
                  }, 800);
                }
              }
            });
          } else {
            v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005899'));
            return false;
          }
        }
      }
      // 扫描拣货单号/出库单号/运单号/云卖面单
      else if (value === 2) {
        if (this.pageParams.waybillNo !== '') {
          if (v.wmsPickupOrderId) {
            v.verifyIssue().then(() => {
              v.waybillNoDisabled = true;
              v.axios.post(api.post_wmsPickupOrderDetail_scanPackaging +
                `${v.pageParams.waybillNo}?wmsPickupOrderId=${v.wmsPickupOrderId}&warehouseId=${v.getWarehouseId()}`).then(response => {
                v.waybillNoDisabled = false;
                v.pageParams.waybillNo = '';
                setTimeout(() => {
                  v.getFocus('deliveryOrder');
                }, 800);
                if (response.data.code === 0) {
                  let data = response.data.datas;
                  if (data) {
                    v.tableData = [...data, ...v.tableData];
                  }
                } else if (response.data.code === 411013) {
                  v.$Modal.confirm({
                    title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005492'),
                    content: alias1bddc4d174174d47a6dfb0437dace856.t('key1005493'),
                    okText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000138'),
                    onOk: () => {
                    },
                    onCancel: () => {
                    }
                  });
                }
              });
            });
          } else {
            v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005898'));
            v.pageParams.waybillNo = '';
            v.waybillNoDisabled = false;
          }
        } else {
          v.waybillNoDisabled = false;
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005900'));
          return false;
        }
      }
    },
    // 扫描出库单，运单号，物流商单号时，需进行出库单状态校验，若为订单已取消状态的出库单，则弹窗提示，点击知道了弹窗消失，保持在本页面
    verifyIssue() {
      let v = this;
      return new Promise((resolve) => {
        v.axios.post(api.post_wmsPickupOrderDetail_checkScanPackaging +
          `${v.pageParams.waybillNo}?wmsPickupOrderId=${v.wmsPickupOrderId}&warehouseId=${v.getWarehouseId()}`).then(res => {
          if (res.data.code === 0) {
            let data = res.data.datas;
            if (data.length > 0) {
              v.$refs['orderCancelPrompt'].basicData = data;
              v.$refs['orderCancelPrompt'].orderCancelPromptModal = true;
            } else {
              resolve(true);
            }
          }
        });
      });
    },
    // 继续装箱
    Packing() {
      let v = this;
      // 继续装箱
      if (v.type === 'continue') {
        v.tableLoading = true;
        v.axios.get(api.get_wmsPickupOrder_continuePickupOrder + `${v.pickupOrderNumber}`).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            v.pageParams.bussinessId = data.pickupOrderNumber;
            v.wmsPickupOrderId = data.wmsPickupOrderId;
            v.tableData = data.wmsPickupOrderDetails || [];
            v.$nextTick(() => {
              v.getFocus('deliveryOrder');
              v.tableLoading = false;
            });
          } else {
            v.tableLoading = false;
          }
        });
      }
      // 新增箱/袋
      else if (v.type === 'adding') {
        v.tableLoading = true;
        v.axios.post(api.post_wmsPickupOrder).then(response => {
          v.tableLoading = false;
          if (response.data.code === 0) {
            let data = response.data.datas;
            v.pageParams.bussinessId = data.pickupOrderNumber;
            v.wmsPickupOrderId = data.wmsPickupOrderId;
            v.$nextTick(() => {
              v.getFocus('bussinessId');
              v.tableLoading = false;
            });
          } else {
            v.tableLoading = false;
          }
        });
      }
    },
    // 删除已扫描的出库单
    deletePackage(wmsPickupOrderDetailId) {
      let v = this;
      v.$Modal.confirm({
        title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005901'),
        onOk: () => {
          v.axios.delete(api.delete_wmsPickupOrderDetail_deletePackage + `${wmsPickupOrderDetailId}`).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000310'));
              v.Packing();
              v.changeEnterKey(1);
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    // 打印箱唛
    printCaseMark() {
      if (this.pageParams.bussinessId !== '') {
        if (this.wmsPickupOrderId) {
          this.$refs['printTalg'].getPrintData(this.wmsPickupOrderId)
        } else {
          this.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005898'));
          return false;
        }
      } else {
        this.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005899'));
        return false;
      }
    },
    // 处理已扫描出库单的数据
    handerData(data) {
      let v = this;
      let newList = [];
      if (data.length > 0) {
        data.map((item, idx) => {
          if (item.wmsPickGoodsBo && item.wmsPickGoodsBo.goodsDetailBos) {
            item.wmsPickGoodsBo.goodsDetailBos.map((ele, index) => {
              let length = item.wmsPickGoodsBo.goodsDetailBos.length;
              ele['length'] = length;
              ele['currentTalg'] = (length > 1 && index === 0);
              ele['packageCode'] = item.packageCode;
              ele['trackingNumber'] = item.trackingNumber;
              ele['ymsWarehouseId'] = item.ymsWarehouseId;
              ele['wmsPickupOrderDetailId'] = item.wmsPickupOrderDetailId;
              ele['path'] = ele.productGoodsImages ? ele.productGoodsImages[0].path : '';
              ele['valueList'] = [];
              if (ele.productGoodsSpecifications && ele.productGoodsSpecifications.length > 0) {
                ele.productGoodsSpecifications.map((talg) => {
                  ele['valueList'].push(talg.value);
                });
                ele['valueList'] = v.uniqueFunc(ele['valueList']);
              }
              newList.push(ele);
            });
          }
        });
        if (newList.length > 0) {
          let productGoodsIds = [];
          newList.map((item, index) => {
            item['tableLength'] = newList.length;
            if (item.currentTalg) {
              item['currentIndex'] = index;
            }
            productGoodsIds.push(item.productGoodsId);
          });
          let list = v.uniqueFunc(productGoodsIds);
          if (list.length > 0) {
            (async () => {
              v.handerTableData = await v.getProductInfoByIds(list, newList,
                false, false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
            })();
          }
        }
      } else {
        v.handerTableData = [];
      }
    },
    // 合并单元格
    handleSpan({row, column, rowIndex, columnIndex}) {
      let columnList = [0, 1];
      if (columnList.includes(columnIndex)) {
        if (row.length > 1) {
          if (rowIndex === row.currentIndex) {
            return [row.length, 1]
          } else {
            for (let i = 0; i <= row.tableLength; i++) {
              if (rowIndex === i) {
                return [0, 0]
              }
            }
          }
        }
      }
    },
    changeTabs(obj) {
      this.$emit('changeTabs', obj);
    }
  },
  watch: {
    tableData: {
      handler(data) {
        this.handerData(data);
      },
      immediate: true,
      deep: true
    }
  },
  components: {
    printCaseMarkModal,
    orderCancelPromptModal,
    logisticsInfoModal
  }
};
</script>
